<template>
  <div class="content">
    <div class="container_body">
      <article class="link_container_body">
        <h1>关于{{ systemName }}</h1>
        <div class="article-content">
          <h2>概况</h2>
          <p>
            {{
              systemName
            }}，打造“互联网+不动产”智慧办理模式，全自治区统一架构、多级联动的不动产登记便民服务平台。
          </p>
          <h2>功能板块</h2>
          <p>2个主体功能板块：个人办事、机构办事；</p>
          <p>
            5个专项功能板块：服务大厅、政策法规、办事指南、资料下载、留言板
            在各大板块中，还按主体设置了不同的服务栏目，提供多种热点应用。
            利用“互联网+”及大数据技术，构建线上线下一体的互联网登记服务平台，通过门户网站、微信公众号、手机APP、自助服务终端等多种渠道，提供不动产登记在线申请、办理预约、网络缴费、自助查询等功能，为我自治区不动产登记的便民利民提供全方位支撑。
          </p>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      systemName: window.CONFIG.APP_INFO.systemName,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/link.scss";
</style>
